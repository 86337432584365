<template>
  <div class="linked-account-component">
    <template v-if="accounts.length">
      <div v-for="(account, index) in accounts" :key="index" class="account is-relative">
        <div class="icon-delete is-flex is-justify-content-center is-align-items-center is-clickable" @click="showDeleteModalAction(account.hashid)">
          <img src="@assets/icons/close-thick.svg" alt="delete">
        </div>
        <div class="account-name">{{account.accountName}}</div>
        <div class="account-number">
          <span class="dots">•••• </span>
          <span class="has-text-weight-semibold last-four-digits"> {{account.accountNumberLast4}}</span>
          <span class="flag is-capitalized">({{account.plaidAccount ? 'Plaid ' : ''}}{{account.status}})</span>
        </div>
      </div>
    </template>
    <div
      v-else
      class="empty-state has-text-lavender is-flex is-justify-content-center is-align-items-center"
    >Link an account to get started! 🏦 </div>
    <div :class="`modal px-5 ${showDeleteModal ? 'is-active' : ''}`">
      <div class="modal-background is-clickable" @click="closeDeleteModal"></div>
      <div class="modal-content has-background-white has-text-centered">
        <img src="@assets/icons/close.svg" alt="close" class="icon-close is-clickable" @click="closeDeleteModal">
        <div class="modal-header">Remove account?</div>
        <div
          class="modal-text has-text-left"
        >Are you sure you want to remove this EFT account from your addy account? We cannot revert this action. You would need to link it again if you change your mind.</div>
        <div class="is-flex is-justify-content-space-between buttons">
          <b-button type="button-cancel" class="has-text-weight-bold is-clickable" @click="closeDeleteModal">Cancel</b-button>
          <b-button type="is-blue button-continue" :loading="isDeleting" class="has-text-weight-bold is-clickable" @click="doDeleteAccount">Yes</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getConnectedAccounts, removeConnectedAccount } from '@api/wallet'
import { fetchConnectedAccounts as fetchAccountsForEntity, removeConnectedAccount as removeAccountForEntity } from '@api/addy-plus/wallet'

export default {
  data() {
    return {
      accounts: [],
      showDeleteModal: false,
      hashid: '',
      isDeleting: false,
    }
  },
  created() {
    this.getAccounts()
  },
  computed: {
    ownedByEntity() {
      return this.$route.path.startsWith('/addyPlus')
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  methods: {
    async getAccounts() {
      const data = await (this.ownedByEntity ? fetchAccountsForEntity(this.corporationId) : getConnectedAccounts())
      if (!data.success) return
      const { accountList } = data.data
      this.accounts = accountList
      this.$emit('syncNumberOfAccount', !!(accountList && accountList.length))
    },
    showDeleteModalAction(hashid) {
      this.hashid = hashid
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
    },
    async doDeleteAccount() {
      this.isDeleting = true
      const result = await (this.ownedByEntity ? removeAccountForEntity(this.corporationId, this.hashid) : removeConnectedAccount(this.hashid))
      this.isDeleting = false
      if (!result.success) return

      this.getAccounts()
      this.showDeleteModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.linked-account-component {
  .empty-state {
    height: 86px;
    font-size: 16px;
    border: 1px solid #F0EEF8;
    border-radius: 12px;
  }
  .account {
    height: 86px;
    background: #F0EEF8;
    border-radius: 12px;
    padding-left: 22px;
    margin-bottom: 12px;
    .icon-delete {
      width: 30px;
      height: 30px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 6px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .account-name {
      font-size: 20px;
      padding-top: 15px;
      padding-bottom: 16px;
    }
    .account-number {
      .dots {
        vertical-align: text-bottom;
        letter-spacing: 0.1em;
      }
      .last-four-digits {
        font-size: 16px;
      }
      .flag {
        margin-left: 6px;
      }
    }
  }
  .modal {
    .modal-content {
      max-width: $mobile-wrapper-width;
      position: relative;
      border-radius: 12px;
      padding: 48px 20px 24px;
      .icon-close {
        width: 30px;
        position: absolute;
        left: 16px;
        top: 14px;
      }
      .modal-header {
        font-weight: 600;
        font-size: 28px;
        padding-bottom: 20px;
      }
      .modal-text {
        font-size: 16px;
        padding: 0 10px 30px;
        line-height: 20px;
      }
      .buttons {
        padding: 0 6px;
        .button {
          width: calc(50% - 4px);
          font-size: 16px;
          height: 43px;
          border-radius: 12px;
        }
        .button-cancel {
          border-color: #4A26AA;
        }
      }
    }
  }
}
</style>
<style scoped>
.button-cancel >>> span {
  color: #4A26AA;
}
</style>
